<template>
  <CCard>
    <CCardHeader>
      Add Router
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="6">
          <CRow>
            <CCol sm="12">
              <CInput
                placeholder="Router Name"
                class="mb-4"
                prepend="Name"
                type="text"
                :value.sync="routerName"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Like host.router.com"
                class="mb-4"
                prepend="Dns name"
                type="text"
                :value.sync="dnsName"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Like 198.18.0.0"
                class="mb-4"
                prepend="Router IP"
                type="text"
                :value.sync="routerIP"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="domain or ip address"
                class="mb-4"
                prepend="Api Connect address"
                type="text"
                :value.sync="host"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Api port(Default api:8728 ssl-api:8729)"
                class="mb-4"
                prepend="Api port"
                type="number"
                min="1"
                max="65535"
                :value.sync="port"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Username"
                class="mb-4"
                prepend="Username"
                type="text"
                :value.sync="username"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Password"
                class="mb-4"
                prepend="Password"
                type="password"
                :value.sync="password"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="Location"
                class="mb-4"
                prepend="Location"
                type="text"
                :value.sync="routerLocation"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CInput
                placeholder="ISP"
                class="mb-4"
                prepend="ISP"
                type="text"
                :value.sync="isp"
              >
              </CInput>
            </CCol>
            <CCol sm="12">
              <CButton
                color="primary"
                square
                @click="addRouter"
              >
                Submit
              </CButton>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>
    <CToaster :autohide="3000">
      <template v-for="toast in fixedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
          :color="alertLevel"
          header="Alert"
        >
          {{ returnMsg }}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Request Message"
      color="primary"
      :show.sync="requestMsgModal"
    >
      {{ requestMsg }}
      <template #footer>
        <CButton @click="requestMsgModal=false" color="primary">Close</CButton>
      </template>
    </CModal>
  </CCard>
</template>

<script>
export default {
  name: "Add",
  data() {

    return {
      fixedToasts: 0,
      returnMsg: "",
      alertLevel: "success",
      requestMsgModal: false,
      requestMsg: null,
      routerName: null,
      dnsName: null,
      routerIP: null,
      host: null,
      port: null,
      username: null,
      password: null,
      routerLocation: null,
      isp: null
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {

    },
    addRouter: function() {
      this.$axios.post("user/router/add", {
        name: this.routerName,
        router_ip: this.routerIP,
        dns_name: this.dnsName,
        host: this.host,
        port: this.port,
        username: this.username,
        password: this.password,
        location: this.routerLocation,
        isp: this.isp
      })
        .then((response) => {
          this.requestMsg = response.data.msg;
          this.requestMsgModal = true;
        });
    }
  }
};
</script>